import { Grid, Table, TableBody, TableContainer, TableRow } from "@mui/material";
import { CheckBoxComponent } from "@syncfusion/ej2-react-buttons";
import { AutoCompleteComponent } from "@syncfusion/ej2-react-dropdowns";
import { useEffect, useState } from "react";
import "style/form.css";
import { BodyTableCell, FormDropDown, FormNumericBox, FormTextBox, LinkButton, TitleTableCell } from "style/theme";
import CRMApi from "system/api/CRMApi";
import ERPApi from "system/api/ERPApi";
import { ErrorHandler } from "system/ApiService";
import { useUserState } from "system/context/UserContext";
import { BaseModel, CostInfo, CSR, INIT_SOLIST, SOList } from "system/types";

interface ReportSOLineProps {
  data: CSR;
  setData: React.Dispatch<React.SetStateAction<any>>;
  issueType: BaseModel[];
  storageType: BaseModel[];
  salesPool: BaseModel[];
  divmode: BaseModel[];
}

function ReportSOLine({ data, setData, issueType, storageType, salesPool, divmode }: ReportSOLineProps) {
  const user = useUserState();
  const [itemList, setItemList] = useState<{ id: string; name: string }[]>([]);

  useEffect(() => {
    CRMApi.GetItemInfo()
      .then((res) => {
        setItemList(res);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        console.log(msg);
      });
  }, []);

  const addSOList = () => {
    const newSO: SOList = { ...INIT_SOLIST, caseId: data.caseId, lineNum: getNextLinenum() };
    const updatedSOList = [...data.soList, newSO];
    setData((prevData: CSR) => ({
      ...prevData,
      soList: updatedSOList,
    }));
  };

  const getNextLinenum = () => {
    // 여기서는 기존의 SOList를 돌며 가장 큰 linenum을 찾아서 +1을 해주는 방식으로 할 수 있습니다.
    const maxLinenum = data.soList.reduce((max, soItem) => (soItem.lineNum > max ? soItem.lineNum : max), 1);
    return maxLinenum + 1;
  };

  const deleteSOList = (event: any, index: number) => {
    const selectData = data.soList[index];
    const updatedSOList = [...data.soList];

    if (selectData.soId) {
      if (selectData.salesID) {
        alert("해당 항목은 삭제할 수 없습니다.");
      } else {
        CRMApi.DeleteCSRSO(selectData)
          .then((res) => {
            updatedSOList.splice(index, 1);
            setData((prevData: CSR) => ({
              ...prevData,
              soList: updatedSOList,
            }));
          })
          .catch((err) => {
            let msg = ErrorHandler(err);
            console.log(msg);
          });
      }
    } else {
      updatedSOList.splice(index, 1);
      setData((prevData: CSR) => ({
        ...prevData,
        soList: updatedSOList,
      }));
    }
  };

  const handleItemChange = async (value: string, index: number) => {
    let updatedData = {};
    const selectedItem = itemList.find((item) => item.id === value);
    if (selectedItem) {
      updatedData = { itemId: value, itemName: selectedItem.name, qty: 1 };
    }
    const priceData = await getPriceData(value);
    updatedData = { ...updatedData, ...priceData };
    updateSOList(index, updatedData);
  };

  const handleItemChangeByName = async (value: Object, index: number) => {
    let updatedData = {};
    const selectedItem = itemList.find((item) => item.name === value);
    if (selectedItem) {
      updatedData = { itemId: selectedItem.id, itemName: value, qty: 1 };
      const priceData = await getPriceData(selectedItem.id);
      updatedData = { ...updatedData, ...priceData };
      updateSOList(index, updatedData);
    }
  };

  const getPriceData = async (itemId: string) => {
    try {
      const res: CostInfo[] = await ERPApi.GetProductCost(itemId);
      const Amount: number = parseInt(res[0].amount) || 0;
      return {
        unitsPrice: Amount,
        supplyPrice: Amount,
        salesPrice: Math.round(Amount * 1.1 * 1),
      };
    } catch (error) {
      alert("Error fetching price data");
      return { unitsPrice: 0 };
    }
  };

  const updateSOList = (index: number, newData: any) => {
    const updatedSOList = [...data.soList];
    updatedSOList[index] = { ...updatedSOList[index], ...newData };
    const updatedData = { ...data, soList: updatedSOList };
    setData(updatedData);
  };

  const onChangeSOItem = (e: any, index: number) => {
    const updatedSOList = [...data.soList];
    const qty = e.target.value;
    const supplyPrice = updatedSOList[index].supplyPrice;
    const salesPrice = Math.round(supplyPrice * qty * 1.1);

    updatedSOList[index] = {
      ...updatedSOList[index],
      qty: qty,
      salesPrice: salesPrice,
    };

    const updatedData = { ...data, soList: updatedSOList };
    setData(updatedData);
  };

  const onChangeSO = (e: any, index: number) => {
    const updatedSOList = [...data.soList];
    const fieldName = e.target.name as keyof SOList;
    const newValue = e.target.value;

    // 'noPackingSlip' 필드를 별도로 처리
    if (fieldName === "noPackingSlip") {
      updatedSOList[index] = {
        ...updatedSOList[index],
        [fieldName]: e.target.checked ? "Yes" : "No",
      };
    } else if (fieldName === "supplyPrice") {
      const qty = updatedSOList[index].qty;
      updatedSOList[index] = {
        ...updatedSOList[index],
        supplyPrice: newValue,
        salesPrice: Math.round(newValue * qty * 1.1),
      };
    } else if (
      fieldName === "issueTypeId" ||
      fieldName === "inventLocationId" ||
      fieldName === "salesPool" ||
      fieldName === "divMode"
    ) {
      updatedSOList.forEach((s: SOList, i) => {
        if (s[fieldName] === "" || s[fieldName] === null || s[fieldName] === undefined) {
          updatedSOList[i] = {
            ...updatedSOList[i],
            [fieldName]: newValue,
          };
        } else {
          updatedSOList[index] = {
            ...updatedSOList[index],
            [fieldName]: newValue,
          };
        }
      });
    } else {
      // 현재 변경된 필드 업데이트
      updatedSOList[index] = {
        ...updatedSOList[index],
        [fieldName]: newValue,
      };
    }

    const updatedData = {
      ...data,
      soList: updatedSOList,
    };
    setData(updatedData);
  };

  return (
    <Grid item xs={12}>
      <TableContainer>
        <Table>
          <TableBody>
            <TableRow>
              <TitleTableCell style={{ width: "50px" }} align="center">
                서비스내역
              </TitleTableCell>
            </TableRow>
            <TableRow>
              <TitleTableCell style={{ width: "50px" }} align="center">
                <LinkButton onClick={addSOList}>추가</LinkButton>
              </TitleTableCell>
              <TitleTableCell align="center">품목번호</TitleTableCell>
              <TitleTableCell align="center">품목명</TitleTableCell>
              <TitleTableCell align="center">수량</TitleTableCell>
              <TitleTableCell align="center">단가(원)</TitleTableCell>
              <TitleTableCell align="center">공급가(원)</TitleTableCell>
              <TitleTableCell align="center">부가세</TitleTableCell>
              <TitleTableCell align="center">금액(원)</TitleTableCell>
              <TitleTableCell align="center">불량 S/N</TitleTableCell>
              <TitleTableCell align="center">교체 S/N</TitleTableCell>
              <TitleTableCell align="center">출고 S/N</TitleTableCell>
              <TitleTableCell align="center">비고</TitleTableCell>
              {user.authority !== "BranchUser" && (
                <>
                  <TitleTableCell align="center">판매주문 출고유형</TitleTableCell>
                  <TitleTableCell align="center">창고</TitleTableCell>
                  <TitleTableCell align="center">모음</TitleTableCell>
                  <TitleTableCell align="center">납품 모드</TitleTableCell>
                  <TitleTableCell align="center">출고 없음</TitleTableCell>
                  <TitleTableCell align="center">판매주문생성</TitleTableCell>
                  <TitleTableCell align="center">상태</TitleTableCell>
                </>
              )}
            </TableRow>
            {data.soList.map((so: SOList, index: number) => (
              <TableRow key={index}>
                {so.salesID === "" ? (
                  <>
                    <BodyTableCell align="center">
                      <LinkButton onClick={(e: any) => deleteSOList(e, index)}>삭제</LinkButton>
                    </BodyTableCell>
                    <BodyTableCell align="center">
                      <AutoCompleteComponent
                        name="itemId"
                        cssClass="e-outline e-small"
                        style={{ whiteSpace: "nowrap", minWidth: "100px" }}
                        fields={{ value: "id" }}
                        dataSource={itemList}
                        value={so.itemId}
                        onChange={(args: any) => handleItemChange(args.value, index)}
                      ></AutoCompleteComponent>
                    </BodyTableCell>
                    <BodyTableCell align="center">
                      <AutoCompleteComponent
                        name="itemName"
                        cssClass="e-outline e-small"
                        style={{ whiteSpace: "nowrap", minWidth: "200px" }}
                        fields={{ value: "name" }}
                        dataSource={itemList}
                        value={so.itemName}
                        onChange={(args: any) => handleItemChangeByName(args.value, index)}
                        popupWidth="400px"
                      ></AutoCompleteComponent>
                    </BodyTableCell>
                    <BodyTableCell align="center">
                      <FormNumericBox
                        name="qty"
                        format="n0"
                        min={0}
                        cssClass="e-outline e-small"
                        style={{ whiteSpace: "nowrap", minWidth: "50px" }}
                        value={so.qty}
                        onChange={(args: any) => onChangeSOItem(args, index)}
                      ></FormNumericBox>
                    </BodyTableCell>
                    <BodyTableCell align="center" style={{ whiteSpace: "nowrap" }}>
                      {so.unitsPrice.toLocaleString()}
                    </BodyTableCell>
                    <BodyTableCell align="center">
                      <FormNumericBox
                        name="supplyPrice"
                        format="n0"
                        min={0}
                        step={1}
                        cssClass="e-outline e-small"
                        style={{ whiteSpace: "nowrap", minWidth: "80px" }}
                        value={so.supplyPrice}
                        onChange={(args: any) => onChangeSO(args, index)}
                      ></FormNumericBox>
                    </BodyTableCell>
                    <BodyTableCell align="center" style={{ whiteSpace: "nowrap" }}>
                      {"10 %"}
                    </BodyTableCell>
                    <BodyTableCell align="center" style={{ whiteSpace: "nowrap" }}>
                      {so.salesPrice.toLocaleString()}
                    </BodyTableCell>
                    <BodyTableCell align="center">
                      <FormTextBox
                        name="ngSerialNum"
                        cssClass="e-outline e-small"
                        style={{ whiteSpace: "nowrap", minWidth: "120px" }}
                        value={so.ngSerialNum}
                        onChange={(e: any) => onChangeSO(e, index)}
                      ></FormTextBox>
                    </BodyTableCell>
                    <BodyTableCell align="center">
                      <FormTextBox
                        name="newSerialNum"
                        cssClass="e-outline e-small"
                        style={{ whiteSpace: "nowrap", minWidth: "120px" }}
                        value={so.newSerialNum}
                        onChange={(e: any) => onChangeSO(e, index)}
                      ></FormTextBox>
                    </BodyTableCell>
                    <BodyTableCell align="center">
                      <FormTextBox
                        name="exfacSerialNum"
                        cssClass="e-outline e-small"
                        style={{ whiteSpace: "nowrap", minWidth: "120px" }}
                        value={so.exfacSerialNum}
                        onChange={(e: any) => onChangeSO(e, index)}
                      ></FormTextBox>
                    </BodyTableCell>
                    <BodyTableCell align="center">
                      <FormTextBox
                        name="note"
                        cssClass="e-outline e-small"
                        style={{ whiteSpace: "nowrap", minWidth: "120px" }}
                        value={so.note}
                        onChange={(e: any) => onChangeSO(e, index)}
                      ></FormTextBox>
                    </BodyTableCell>
                    {user.authority !== "BranchUser" && (
                      <>
                        <BodyTableCell align="center">
                          <FormDropDown
                            name="issueTypeId"
                            cssClass="e-outline e-small"
                            dataSource={issueType}
                            allowFiltering={true}
                            fields={{ text: "name", value: "id" }}
                            style={{ whiteSpace: "nowrap", width: "120px" }}
                            value={so.issueTypeId || ""}
                            onChange={(e: any) => onChangeSO(e, index)}
                            popupWidth="200px"
                            disabled={!["Admin", "HeadA"].includes(user.authority)}
                          ></FormDropDown>
                        </BodyTableCell>
                        <BodyTableCell align="center">
                          <FormDropDown
                            name="inventLocationId"
                            cssClass="e-outline e-small"
                            dataSource={storageType}
                            allowFiltering={true}
                            fields={{ text: "name", value: "id" }}
                            style={{ whiteSpace: "nowrap", width: "120px" }}
                            value={so.inventLocationId || ""}
                            onChange={(e: any) => onChangeSO(e, index)}
                            popupWidth="250px"
                            disabled={!["Admin", "HeadA"].includes(user.authority)}
                          ></FormDropDown>
                        </BodyTableCell>
                        <BodyTableCell align="center">
                          <FormDropDown
                            name="salesPool"
                            cssClass="e-outline e-small"
                            dataSource={salesPool}
                            allowFiltering={true}
                            fields={{ text: "name", value: "id" }}
                            style={{ whiteSpace: "nowrap", minWidth: "80px" }}
                            value={so.salesPool || ""}
                            onChange={(e: any) => onChangeSO(e, index)}
                            popupWidth="150px"
                            disabled={!["Admin", "HeadA"].includes(user.authority)}
                          ></FormDropDown>
                        </BodyTableCell>
                        <BodyTableCell align="center">
                          <FormDropDown
                            name="divMode"
                            cssClass="e-outline e-small"
                            dataSource={divmode}
                            allowFiltering={true}
                            fields={{ text: "name", value: "id" }}
                            style={{ whiteSpace: "nowrap", minWidth: "80px" }}
                            value={so.divMode || ""}
                            onChange={(e: any) => onChangeSO(e, index)}
                            popupWidth="150px"
                            disabled={!["Admin", "HeadA"].includes(user.authority)}
                          ></FormDropDown>
                        </BodyTableCell>
                        <BodyTableCell align="center">
                          <CheckBoxComponent
                            cssClass="e-small"
                            name="noPackingSlip"
                            checked={so.noPackingSlip === "Yes"}
                            onChange={(e: any) => onChangeSO(e, index)}
                            disabled={!["Admin", "HeadA"].includes(user.authority)}
                          ></CheckBoxComponent>
                        </BodyTableCell>
                        <BodyTableCell align="center">{so.salesID}</BodyTableCell>
                        <BodyTableCell align="center"></BodyTableCell>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <BodyTableCell align="center"></BodyTableCell>
                    <BodyTableCell align="center">{so.itemId}</BodyTableCell>
                    <BodyTableCell align="center">{so.itemName}</BodyTableCell>
                    <BodyTableCell align="center">{so.qty}</BodyTableCell>
                    <BodyTableCell align="center" style={{ whiteSpace: "nowrap" }}>
                      {so.unitsPrice.toLocaleString()}
                    </BodyTableCell>
                    <BodyTableCell align="center">{so.supplyPrice}</BodyTableCell>
                    <BodyTableCell align="center" style={{ whiteSpace: "nowrap" }}>
                      {"10 %"}
                    </BodyTableCell>
                    <BodyTableCell align="center" style={{ whiteSpace: "nowrap" }}>
                      {so.salesPrice.toLocaleString()}
                    </BodyTableCell>
                    <BodyTableCell align="center">{so.ngSerialNum}</BodyTableCell>
                    <BodyTableCell align="center">{so.newSerialNum}</BodyTableCell>
                    <BodyTableCell align="center">{so.exfacSerialNum}</BodyTableCell>
                    <BodyTableCell align="center">{so.note}</BodyTableCell>
                    <BodyTableCell align="center">{issueType.find((x) => x.id === so.issueTypeId)?.name}</BodyTableCell>
                    <BodyTableCell align="center">
                      {storageType.find((x) => x.id === so.inventLocationId)?.name}
                    </BodyTableCell>
                    <BodyTableCell align="center">{salesPool.find((x) => x.id === so.salesPool)?.name}</BodyTableCell>
                    <BodyTableCell align="center">{divmode.find((x) => x.id === so.divMode)?.name}</BodyTableCell>
                    <BodyTableCell align="center">
                      <CheckBoxComponent
                        cssClass="e-small"
                        name="noPackingSlip"
                        checked={so.noPackingSlip === "Yes"}
                        onChange={(e: any) => onChangeSO(e, index)}
                        disabled={true}
                      ></CheckBoxComponent>
                    </BodyTableCell>
                    <BodyTableCell align="center">{so.salesID}</BodyTableCell>
                    <BodyTableCell align="center"></BodyTableCell>
                  </>
                )}
              </TableRow>
            ))}
            <TableRow>
              <TitleTableCell align="center" colSpan={7}>
                합계(원)
              </TitleTableCell>
              <BodyTableCell align="center">
                {data.soList.reduce((total, so) => (total = total + so.salesPrice), 0).toLocaleString()}
              </BodyTableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
}

export default ReportSOLine;
