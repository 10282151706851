import { enableRipple } from "@syncfusion/ej2-base";
import { BrowserRouter } from "react-router-dom";
import Routes from "routes/Routes";
import { LoadingContextProvider } from "system/context/LoadingContext";
import { UserContextProvider } from "system/context/UserContext";
import "./App.css";
enableRipple(true);

function App() {
  return (
    <UserContextProvider>
      <LoadingContextProvider>
        <BrowserRouter>
          <Routes></Routes>
        </BrowserRouter>
      </LoadingContextProvider>
    </UserContextProvider>
  );
}

export default App;
