import client from "system/ApiService";
import HttpClient from "system/http-client";
import { CSR, CustDate, ProdDate, SalesDate } from "system/types";

class _ERPApi {
  private client: HttpClient;
  public constructor(client: HttpClient) {
    this.client = client;
  }
  // 실시간 api
  GetProductCost(serialNum: string) {
    return this.client.get(`erp/productcost/${serialNum}`);
  }
  GetProdTransList(serialNum: string) {
    return this.client.get(`erp/prodtrans/${serialNum}`);
  }

  // ERP 데이터 전송
  InsertCSRtoERP(caseId: string) {
    return this.client.get(`erp/csr/${caseId}`);
  }
  InsertSOtoERP(data: CSR) {
    return this.client.post(`erp/so`, data); // so만 생성(사용안함)
  }
  InsertCSRAndSO(data: CSR) {
    return this.client.post(`erp/csr-and-so`, data); // ERP 전송 후 so 생성
  }

  // 기준정보 update
  // 생성 판매 기준 정보
  UpdateMasterDataOfProdInfo(prodDate: ProdDate) {
    return this.client.post(`erp/prodinfo`, prodDate);
  }
  UpdateMasterDataOfSalesInfo(salesDate: SalesDate) {
    return this.client.post(`erp/salesinfo`, salesDate);
  }
  // 고객
  UpdateMasterDataOfCustInfo(custDate: CustDate) {
    return this.client.post("erp/custinfo", custDate);
  }
  UpdateMasterDataOfCustHistory() {
    return this.client.get("erp/custhistory");
  }
  // item
  UpdateMasterDataOfProductCategory() {
    return this.client.get("erp/productcategory");
  }
  UpdateMasterDataOfModelCategory() {
    return this.client.get("erp/modelcategory");
  }
  // so
  UpdateMasterDataOfIssueType() {
    return this.client.get("erp/issuetype");
  }
  UpdateMasterDataOfStorage() {
    return this.client.get("erp/storage");
  }
  UpdateMasterDataOfSalesPool() {
    return this.client.get("erp/salespool");
  }
  UpdateMasterDataOfDivmode() {
    return this.client.get("erp/divmode");
  }
  UpdateMasterDataOfTaxType() {
    return this.client.get("erp/taxtype");
  }
  // p c a
  UpdateMasterDataOfProject() {
    return this.client.get("erp/project");
  }
  UpdateMasterDataOfCostCenter() {
    return this.client.get("erp/costcenter");
  }
  UpdateMasterDataOfActivity() {
    return this.client.get("erp/activity");
  }
  // ng result
  UpdateMasterDataOfNGCategory() {
    return this.client.get("erp/ngcategory");
  }
  UpdateMasterDataOfNGItemCategory() {
    return this.client.get("erp/ngitemcategory");
  }
  UpdateMasterDataOfResultCateogry() {
    return this.client.get("erp/resultcategory");
  }
  UpdateMasterDataOfResultItemCateogry() {
    return this.client.get("erp/resultitemcategory");
  }
}

const ERPApi = new _ERPApi(client);
export default ERPApi;
